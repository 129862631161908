import React, { Component } from 'react';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import colors from 'styles/colors';

import { client as apolloClient } from 'util/apolloClient';
import { searchCharitiesQuery } from 'graphql/search';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close/close.svg';
import Avatar from 'components/Avatar/Avatar';

const fetchResults = async (inputValue, callback) => {
  if (inputValue) {
    try {
      const result = await apolloClient
        .query({
          query: searchCharitiesQuery,
          variables: { query: inputValue },
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then((res) =>
          res.data.searchCharity.map((charity) => ({
            ...charity,
            value: charity.id,
            label: charity.name,
          })),
        );
      callback(result);
    } catch (err) {
      console.error(err);
    }
  }
};

const animatedComponents = makeAnimated();

const styles = {
  multiValueLabel: (provided, _state) => ({
    ...provided,
    border: 'none',
    whiteSpace: 'normal',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%',
    border: 'none !important',
    boxShadow: 'none',
    outline: 'none',
    fontSize: '16px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },

    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:focus': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:focus-within': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    ...(state.isFocused && {
      border: 'none !important',
      boxShadow: 'none',
      outline: 'none',
    }),
  }),
  container: (provided, _state) => ({
    ...provided,
    height: '100%',
    border: 'none',
    width: '100%',
  }),
  input: (provided, _state) => ({
    ...provided,
    color: '#1a1a1a',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    fontSize: '16px',
    width: '100%',
    height: '38px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
    padding: '0px',
    margin: '0px',
    input: {
      opacity: '1 !important',
      width: '100% !important',
    },
  }),
  a11yText: (provided, _state) => ({
    ...provided,
  }),
  valueContainer: (provided, _state) => ({
    ...provided,
    paddingLeft: '8px',
    fontSize: '16px',
    lineHeight: '100%',
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    color: '#1a1a1a',
    fontSize: '16px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    fontSize: '16px',
    lineHeight: '100%',
    paddingLeft: '4px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    border: 'none',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 999,
    width: '100%',
    border: 'none',
    top: '40px',
    left: '0%',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    position: 'absolute',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.baseColor : 'white',
    color: state.isSelected ? 'white' : '#1a1a1a',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: colors.baseColor,
      color: 'white',
    },
    '&:hover': {
      '@media (min-width: 460px)': {
        backgroundColor: colors.baseColor,
        color: 'white',
      },
    },
  }),
};

const Option = (props) => (
  <components.Option {...props}>
    <div className="flex-row flex-align-center">
      <Avatar
        sm
        style={{ marginRight: '0.5rem' }}
        avatar={props.data.avatar}
        type="charity"
      />
      <div className="flex-column">
        {props.children}, &nbsp;
        {props.data.location} &nbsp;
        <div style={{ fontSize: '0.8rem', color: 'lightgrey' }}>
          EIN: {props.data.ein}
        </div>
      </div>
    </div>
  </components.Option>
);

const debouncedFetchResults = debounce(fetchResults, 300);

const loadOptions = (inputValue, callback) => {
  debouncedFetchResults(inputValue, callback);
};

export default class CharitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.defaultValue };
  }

  removeValue = (index) => {
    let newValue = this.state.value.slice();
    newValue.splice(index, 1);
    this.setState({ value: newValue });
  };

  handleInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  render() {
    return (
      <div className="search-container relative !block !h-[inherit]">
        <AsyncSelect
          value={this.state.value}
          placeholder="Search for a Non-Profit"
          loadOptions={loadOptions}
          defaultOptions={false}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: colors.baseColor,
              primary50: colors.lightGrey,
              primary25: colors.white,
              danger: colors.baseColor,
              dangerLight: colors.baseColorHalfTransparent,
            },
          })}
          styles={styles}
          onInputChange={this.handleInputChange}
          components={{
            ...animatedComponents,
            Option,
            ClearIndicator: () => null,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(value) => {
            this.props.onChange(value);
            this.setState({ value: value });
          }}
          autoLoad={false}
        />
        {Boolean(this.state.value) && (
          <button
            className="absolute top-2 right-1 bg-transparent border-none cursor-pointer"
            onClick={() => {
              this.setState({ value: null });
              this.props.onChange(null);
            }}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    );
  }
}
