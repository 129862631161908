import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import { debounce, isEmpty } from 'lodash';
import { Formik, useFormikContext } from 'formik';

import useUserDetails from 'hooks/useUserDetails';
import { useCauzeRoutingContext } from 'hooks/useCauzeRoutingContext';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import Button from 'components/Button/Button';
import {
  emailValidate,
  zipValidate,
  validateLink,
} from 'util/formFieldValidators';

import userPlaceholderAvatarImage from 'assets/images/icons/placeholder/placeholder-avatar.png';
import placeholder from 'assets/images/placeholders/placeholder-event-image.png';
import { client as api2Client } from 'util/api2Client';
import { userByUsernameQuery } from 'graphql/users';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import ImageUploadModal from 'components/modals/ImageUploadModal/ImageUploadModal';
import CharitySearch from 'components/CharitySearch/ProfileCharitySearch';

const InputContainer = ({ title, error, children, style }) => (
  <div
    style={style}
    className={classnames('input-container flex-column', {
      'input-container-error': error,
    })}
  >
    <p className={classnames('input-title flex-column')}>{title}</p>
    {children}
    <p className="input-container-error-message">{error}</p>
  </div>
);

const validate = ({
  firstName,
  lastName,
  email,
  username,
  zip,
  x,
  youtube,
  instagram,
  facebook,
  tiktok,
  shareLink,
}) => {
  const regex = /^[0-9a-zA-Z]+$/;
  const validationObj = {};

  if (!firstName) {
    validationObj.firstName = 'First name is required';
  }

  if (!lastName) {
    validationObj.lastName = 'Last name is required';
  }

  if (!email || !emailValidate(email)) {
    validationObj.email = 'Invalid email';
  }

  if (!username) {
    validationObj.username = 'Username is required';
  }

  if (!username.replace('@', '').match(regex)) {
    validationObj.username = 'Invalid username';
  }

  if (zip && !zipValidate(zip)) {
    validationObj.zip = 'Invalid zip';
  }

  if (x && !validateLink(x, 'twitter')) {
    validationObj.x = 'Invalid twitter/x URL';
  }

  if (youtube && !validateLink(youtube, 'youtube')) {
    validationObj.youtube = 'Invalid youtube URL';
  }

  if (instagram && !validateLink(instagram, 'instagram')) {
    validationObj.instagram = 'Invalid instagram URL';
  }

  if (facebook && !validateLink(facebook, 'facebook')) {
    validationObj.facebook = 'Invalid facebook URL';
  }

  if (tiktok && !validateLink(tiktok, 'tiktok')) {
    validationObj.tiktok = 'Invalid tiktok URL';
  }

  if (shareLink && !validateLink(shareLink, 'website')) {
    validationObj.shareLink = 'Invalid vanity URL';
  }

  return validationObj;
};

const getUserByUsername = async (username) => {
  const options = {
    variables: {
      username,
    },
    query: userByUsernameQuery,
    errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
  };

  const res = await api2Client.query(options);
  return res?.data?.userByUsername?.username;
};

const UsernameInput = () => {
  const { values, handleChange, setFieldError, initialValues } =
    useFormikContext();

  const validateUsername = useCallback(
    debounce((username) => {
      getUserByUsername(username).then((userUsername) => {
        if (userUsername && initialValues.username !== userUsername) {
          setFieldError('username', 'User with username already exists');
        }
      });
    }),
    [],
  );

  return (
    <input
      type="text"
      name="username"
      value={values.username || ''}
      placeholder={values.username || `@username`}
      onChange={(e) => {
        validateUsername(e.target.value);
        handleChange(e);
      }}
    />
  );
};

const CharityInput = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="border border-solid border-lightgray-c4c rounded-lg p-[1px]">
      <CharitySearch
        defaultValue={{
          ...values.charity,
          value: values.charity?.id?.toString(),
          label: values.charity?.name,
        }}
        onChange={(value) => {
          setFieldValue('charity', value);
        }}
      />
    </div>
  );
};

/* See comment below on <VanityInput />
const VanityInput = () => {
  const { values, setFieldValue } =
    useFormikContext();

  const shareLink = new URL(values.shareLink);

  return (
    <div>
      <span>{`${shareLink.origin}/`}</span>
      <input
        type="text"
        name="shareLink"
        value={shareLink.pathname.substring(1) || ''}
        placeholder={'custom'}
        onChange={(e) => {
          const newShareLink = `${shareLink.origin}/${e.target.value}`;
          setFieldValue('shareLink', newShareLink);
        }}
      />
    </div>
  );
};
*/

const UserProfileForm = ({
  handleHeroImageChange,
  handleUserLogoChange,
  imageChanged,
  setImageChanged,
  savingProfile,
  userDetailsStore,
  isInfluencer,
}) => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const {
    submitForm,
    errors,
    values,
    handleChange,
    handleBlur,
    initialValues,
  } = useFormikContext();
  const { setConfirmProps } = useCauzeRoutingContext();
  const [upImg, setUpImg] = useState(placeholder);
  const [showFileError, setShowFileError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const hasUnsavedChanges = useMemo(() => {
    let unsaved = false;

    if (imageChanged) {
      return true;
    }

    Object.keys(initialValues).forEach((key) => {
      unsaved ||= initialValues[key] !== values[key];
    });

    return unsaved;
  }, [initialValues, values, imageChanged]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3000000) {
        setShowFileError(
          'File is too large. Please select a file less than 3MB.',
        );
        return;
      }
      setIsChanged(true);
      setShowImageUploadModal(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onFileUpload = (imageFile) => {
    userDetailsStore.setHeroImageFile(imageFile);
  };

  // show confirmation on browser route change and refresh
  useEffect(() => {
    if (hasUnsavedChanges) {
      setConfirmProps({
        message: 'Do you want to save your changes?',
        bodyMessage:
          'You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.',
        confirmText: 'Continue',
      });
    } else {
      setConfirmProps(null);
    }

    const onBeforeUnload = (e) => {
      if (isIOS) {
        // TODO: handle IOS at a later date
      } else {
        if (hasUnsavedChanges) {
          e.preventDefault();
          e.returnValue = '';
        }
      }
    };

    if (hasUnsavedChanges) {
      if (!isIOS) {
        window.addEventListener('beforeunload', onBeforeUnload);
      }
    } else {
      if (!isIOS) {
        window.removeEventListener('beforeunload', onBeforeUnload);
      }
    }

    return () => {
      if (!isIOS) {
        window.removeEventListener('beforeunload', onBeforeUnload);
      }
    };
  }, [hasUnsavedChanges, isIOS]);

  useEffect(() => {
    return () => setConfirmProps(null);
  }, []);

  return (
    <div className="user-profile-form">
      <ImageUploadModal
        store={userDetailsStore}
        cropRatio={[544, 181]}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        isChanged={isChanged}
        setIsChanged={setIsChanged}
        upImg={upImg}
        setUpImg={setUpImg}
        showFileError={showFileError}
        setShowFileError={setShowFileError}
        onFileUpload={onFileUpload}
        updateImageUrl={(imageUrl) => {
          userDetailsStore.setHeroImageUrl(imageUrl);
        }}
      />
      <div className="user-profile-form-content flex-1">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <InputContainer title="First Name" error={errors.firstName}>
            <input
              type="text"
              name="firstName"
              value={values.firstName || ''}
              placeholder={values.firstName || `First Name`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer title="Last Name" error={errors.lastName}>
            <input
              type="text"
              name="lastName"
              value={values.lastName || ''}
              placeholder={values.lastName || `Last Name`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer title="Username" error={errors.username}>
            <UsernameInput />
          </InputContainer>
          <div />
          {/* Uncommenting this will allow users to set their own link URL. We may eventually want to
          enable this with an admin approval process, but for now it's only available from the admin page.

          <InputContainer title="Vanity URL" error={errors.shareLink} style={{ gridColumn: 'span 2' }}>
            <VanityInput />
          </InputContainer> */}
          <InputContainer title="Email" error={errors.email}>
            <input
              type="text"
              name="email"
              value={values.email || ''}
              placeholder={values.email || `Email Address`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer title="Backup Email" error={errors.backupEmail}>
            <input
              type="text"
              name="backupEmail"
              value={values.backupEmail || ''}
              placeholder={values.backupEmail || `Backup Email Address`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer title="Zip Code" error={errors.zip}>
            <input
              name="zip"
              value={values.zip || ''}
              placeholder={`Zip`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <div />
          <InputContainer
            title="Bio"
            error={errors.bio}
            style={{ gridColumn: 'span 2' }}
          >
            <textarea
              name="bio"
              value={values.bio || ''}
              placeholder={values.bio || `Tell us who you are`}
              rows={8}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer title="Profile Image">
            <div className="image-input">
              <img
                className="profile-image-logo"
                src={userDetailsStore.userLogoUrl}
              />
              <label className="image-upload-label" htmlFor="logo-upload">
                Select File
              </label>
              <input
                hidden
                type="file"
                id="logo-upload"
                onChange={(e) => {
                  handleUserLogoChange(e);
                  setImageChanged(true);
                }}
              />
            </div>
          </InputContainer>
          <div />
          <InputContainer title="Cover Image" style={{ gridColumn: 'span 2' }}>
            <div className="image-input">
              <div className="profile-hero-image-container">
                {Boolean(values.heroImage) && (
                  <div className="profile-hero-image-overlay" />
                )}
                <div
                  className="profile-hero-image"
                  style={{
                    backgroundImage: `url('${userDetailsStore.heroImageUrl}')`,
                  }}
                />
              </div>
              <label className="image-upload-label" htmlFor="hero-upload">
                Select File
              </label>
              <input
                hidden
                type="file"
                id="hero-upload"
                onChange={onSelectFile}
              />
            </div>
          </InputContainer>
          {isInfluencer && (
            <InputContainer
              title="Charity (optional)"
              error={errors.charityId}
              style={{ gridColumn: 'span 2' }}
            >
              <CharityInput />
            </InputContainer>
          )}
          <InputContainer
            title="Website (optional)"
            error={errors.webUrl}
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="webUrl"
              value={values.webUrl || ''}
              placeholder={`Website`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer
            title="Instagram (optional)"
            error={errors.instagram}
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="instagram"
              value={values.instagram || ''}
              placeholder={`Instagram`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer
            title="Facebook (optional)"
            error={errors.facebook}
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="facebook"
              value={values.facebook || ''}
              placeholder={`Facebook`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer
            title="Twitter/X (optional)"
            error={errors.x}
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="x"
              value={values.x || ''}
              placeholder={`Twitter`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer
            title="TikTok (optional)"
            error={errors.tiktok}
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="tiktok"
              value={values.tiktok || ''}
              placeholder={`TikTok`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <InputContainer
            title="YouTube (optional)"
            error={errors.youtube}
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="youtube"
              value={values.youtube || ''}
              placeholder={`YouTube`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputContainer>
          <div>
            <Button
              className="save-button is-medium max-xl:w-full"
              type="submit"
              outline
              isSubmitting={savingProfile}
              disabled={!isEmpty(errors)}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const UserProfileDetails = ({
  userDetailsStore,
  profileStore,
  userId,
  userContext,
}) => {
  const [imageChanged, setImageChanged] = useState(false);
  const { setProps } = useDashboardStore();
  const { navigateWithConfirm } = useCauzeRoutingContext();

  useUserDetails({
    userId,
    userContext,
  });

  const handleUserLogoChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      userDetailsStore.setUserLogoFile(file);
      userDetailsStore.setUserLogoUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleHeroImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      userDetailsStore.setHeroImageFile(file);
      userDetailsStore.setHeroImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const currentUser = useMemo(
    () => ({
      ...userDetailsStore.newUserData,
      userLogo: userDetailsStore.userLogoUrl || userPlaceholderAvatarImage,
      heroImage: userDetailsStore.heroImageUrl || userPlaceholderAvatarImage,
    }),
    [
      userDetailsStore.newUserData,
      userDetailsStore.userLogoUrl,
      userDetailsStore.heroImageUrl,
    ],
  );

  const handleSubmit = async (values, { resetForm }) => {
    const newUserData = {};

    Object.entries(values).forEach(([key, value]) => {
      if (key === 'charity') {
        newUserData.projectId = parseInt(value?.id) || undefined;
      } else if (currentUser[key] !== value) {
        newUserData[key] = value || '';
      }
    });

    userDetailsStore.updateNewUserData(newUserData);
    const success = await userDetailsStore.saveUserChanges();

    if (success) {
      profileStore.loading = true;
      resetForm();
      setImageChanged(false);

      setTimeout(() => {
        window.location.href = `/profile/${userId}`;
      }, 100);
    }
  };

  useEffect(() => {
    setProps({
      size: 'half',
      title: 'Your Profile',
      actions: (
        <a
          className="profile-link font-agenda-bold"
          onClick={() => navigateWithConfirm(`/profile/${userId}`)}
        >
          View Profile Page
        </a>
      ),
    });
  }, []);

  if (profileStore.loading || isEmpty(userDetailsStore.newUserData)) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="user-details flex-column">
      <div className="user-details-tight flex">
        <Formik
          enableReinitialize
          initialValues={currentUser}
          validate={validate}
          onSubmit={handleSubmit}
        >
          <UserProfileForm
            userId={userId}
            handleUserLogoChange={handleUserLogoChange}
            handleHeroImageChange={handleHeroImageChange}
            savingProfile={userDetailsStore.savingProfile}
            imageChanged={imageChanged}
            setImageChanged={setImageChanged}
            userDetailsStore={userDetailsStore}
            isInfluencer={profileStore?.data?.isInfluencer}
          />
        </Formik>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject('userDetailsStore', 'profileStore')(observer(UserProfileDetails)),
  ),
  { entityType: 'USER' },
);
