import { observable, action, makeObservable } from 'mobx';
import { client as api2Client } from 'util/api2Client';
import { updateProfileMutationV2 } from 'graphql/profile';
import { userProfileByIdQueryV2 } from 'graphql/user';
import config from '../config';
import { getToken } from 'util/storage';
import axios from 'axios';
import uiStore from 'stores/UiStore';
import mixpanel from 'mixpanel-browser';
import { sanitizeLink } from 'util/formFieldValidators';

const UPLOAD_URL = `${config.UPLOAD_ROOT}`;
const USER_LOGO_ENDPOINT = 'avatar';
const USER_LOGO_FORM_KEY = `avatar`;
const HERO_IMAGE_ENDPOINT = 'hero';
const HERO_IMAGE_FORM_KEY = `hero`;

class UserDetailsStore {
  @observable userData = {};
  @observable newUserData = {};

  @observable userLogoUrl = '';
  @observable userLogoFile = {};
  @observable heroImageUrl = '';
  @observable heroImageFile = {};

  @observable userLogoHasChanged = false;
  @observable heroImageHasChanged = false;
  @observable isUploadingUserLogo = false;
  @observable isUploadingHeroImage = false;
  @observable imageUploadError = false;

  @observable savingProfile = false;
  @observable profileUpdateError = false;

  userId = null;

  @action fetchUserDetails = async ({ userId }) => {
    const options = {
      variables: { userId: parseInt(userId) },
      query: userProfileByIdQueryV2,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    const result = await api2Client.query(options);
    if (result?.data?.data) {
      this.importUserDetails(result.data.data);
    }
  };

  @action importUserDetails = ({
    firstName,
    lastName,
    foundationName,
    email,
    backupEmail,
    zip,
    bio,
    username,
    heroUrls,
    avatarUrls,
    id,
    userSocials,
    webUrl,
    shareLink,
    charity,
  } = {}) => {
    this.userData = {
      firstName,
      lastName,
      foundationName,
      email,
      backupEmail,
      zip,
      bio,
      username,
      webUrl,
      shareLink,
      charity,
    };

    if (Array.isArray(userSocials)) {
      userSocials.forEach((social) => {
        this.userData[social.socialType.toLowerCase()] = social.url;
      });
    }

    this.newUserData = this.userData;

    this.userLogoUrl = avatarUrls?.md;
    this.heroImageUrl = heroUrls?.md;
    this.userId = id;
  };

  @action updateNewUserData = (newData) => {
    this.newUserData = { ...this.newUserData, ...newData };
  };

  @action saveUserChanges = async () => {
    this.savingProfile = true;
    try {
      if (this.userLogoHasChanged) {
        await this.submitUserLogo();
      }
      if (this.heroImageHasChanged) {
        await this.submitHeroImage();
      }

      const updateBody = {
        id: parseInt(this.userId),
      };

      const validFields = [
        'zip',
        'firstName',
        'lastName',
        'username',
        'foundationName',
        'email',
        'backupEmail',
        'bio',
        'webUrl',
        'shareLink',
        'projectId',
      ];

      const socialFields = ['x', 'tiktok', 'youtube', 'facebook', 'instagram'];

      validFields.concat(socialFields).forEach((field) => {
        let fieldValue = this.newUserData[field];
        if (field === 'username') {
          fieldValue = fieldValue.replace('@', '');
        }

        if (socialFields.includes(field)) {
          let socialField = field;

          if (socialField === 'x') {
            socialField = 'twitter';
          }

          fieldValue = sanitizeLink(fieldValue, socialField);
        }

        if (field === 'webUrl' || field === 'shareLink') {
          fieldValue = sanitizeLink(fieldValue, 'website');
        }

        if (fieldValue !== this.userData[field]) {
          updateBody[field] = fieldValue ?? '';
        }
      });

      const options = {
        variables: {
          update: updateBody,
        },
        mutation: updateProfileMutationV2,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const res = await api2Client.mutate(options);
      this.savingProfile = false;

      if (res.errors) {
        res.errors.forEach((error) => {
          if (error.message === 'has already been taken') {
            uiStore.showNotification({
              body: `Username has already been taken. Please try another`,
              type: 'ERROR',
            });
          } else if (error.message === 'has invalid format') {
            uiStore.showNotification({
              body: `Username is invalid: lowercase letters and numbers only`,
              type: 'ERROR',
            });
          } else if (error.message.includes('Branch error code')) {
            if (error.message.includes('409: Non-unique alias')) {
              uiStore.showNotification({
                body: `Vanity URL has already been taken. Please try another.`,
                type: 'ERROR',
              });
            } else {
              uiStore.showNotification({
                body: `Error updating vanity URL.`,
                type: 'ERROR',
              });
            }
          }
        });
      } else {
        uiStore.showNotification({
          body: `Profile Updated`,
          type: 'SUCCESS',
        });

        mixpanel.track('Updated Profile', {
          email: this.newUserData.email,
          username: this.newUserData.username,
          firstName: this.newUserData.firstName,
          lastName: this.newUserData.lastName,
          zip: this.newUserData.zip,
        });

        mixpanel.people.set({
          $name: `${this.newUserData.firstName} ${this.newUserData.lastName}`,
          $email: this.newUserData.email.toLowerCase(),
          'First Name': this.newUserData.firstName,
          'Last Name': this.newUserData.lastName,
          Email: this.newUserData.email.toLowerCase(),
          Username: this.newUserData.username,
          Zip: this.newUserData.zip,
        });

        // refresh initial values
        this.newUserData = {
          ...this.newUserData,
        };

        return true;
      }
    } catch (err) {
      this.settingsUpdateError = true;
      this.savingProfile = false;
      uiStore.showNotification({
        body: `There was an issue updating your profile. Support has been notified`,
        type: 'ERROR',
      });
    }

    return false;
  };

  @action setUserLogoUrl = (imgURL) => {
    this.userLogoUrl = imgURL;
  };

  @action setUserLogoFile = (img) => {
    this.userLogoFile = img;
    this.userLogoHasChanged = true;
  };

  @action setHeroImageUrl = (imgURL) => {
    this.heroImageUrl = imgURL;
  };

  @action setHeroImageFile = (img) => {
    this.heroImageFile = img;
    this.heroImageHasChanged = true;
  };

  @action submitUserLogo = async () => {
    if (this.userLogoFile) {
      try {
        this.isUploadingUserLogo = true;

        const token = await getToken();

        const url = `${UPLOAD_URL}/user/${this.userId}/${USER_LOGO_ENDPOINT}`;
        const data = new FormData();
        data.append(USER_LOGO_FORM_KEY, this.userLogoFile);

        await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.isUploadingUserLogo = false;
        this.userLogoHasChanged = false;
      } catch (err) {
        this.isUploadingUserLogo = false;
        this.imageUploadError = true;
      }
    }
  };

  @action submitHeroImage = async () => {
    if (this.heroImageFile) {
      try {
        this.isUploadingHeroImage = true;

        const token = await getToken();

        const url = `${UPLOAD_URL}/user/${this.userId}/${HERO_IMAGE_ENDPOINT}`;
        const data = new FormData();
        data.append(HERO_IMAGE_FORM_KEY, this.heroImageFile);

        await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.isUploadingHeroImage = false;
        this.heroImageHasChanged = false;
      } catch (err) {
        this.isUploadingHeroImage = false;
        this.imageUploadError = true;
      }
    }
  };

  @action onUnmount = () => {
    this.userData = {};
    this.newData = {};
    this.userLogoUrl = '';
    this.heroImageUrl = '';

    this.userLogoFile = {};
    this.heroImageFile = {};
    this.userLogoHasChanged = false;
    this.heroImageHasChanged = false;
    this.isUploadingUserLogo = false;
    this.isUploadingHeroImage = false;

    this.savingProfile = false;
    this.profileUpdateError = false;
    this.imageUploadError = false;

    this.userId = null;
  };

  constructor() {
    makeObservable(this);
  }
}

const userDetailsStore = new UserDetailsStore();
export default userDetailsStore;
