import React, { useState, useEffect, useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import dompurify from 'dompurify';
import { isEmpty } from 'lodash';
import { navigate } from '@reach/router';

import ShareModal from 'components/modals/ShareModal/ShareModal';

import withUserContext from 'behaviors/withUserContext';
import { convertEntityTypeToId } from 'util/contextUtils';

import ProfileAvatar from './ProfileAvatar';
import ProfileAction from './ProfileAction';
import ProfileSocial from './ProfileSocial';
import ProfileMetric from './ProfileMetric';
import { ReactComponent as LinkIcon } from 'assets/images/icons/link/link.svg';
import useMobile from 'hooks/useMobile';

const ProfileHeaderBio = ({ bio }) => {
  const bioMaxCount = 200;
  const [hideMore, setHideMore] = useState(bio.length > bioMaxCount);
  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    function handleClick(e) {
      if (e.target.id === 'profile-read-more') {
        setHideMore(false);
      }
    }

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <span
      className={cx('bio-text', {
        'hide-more': hideMore && bio.length > 200,
      })}
      dangerouslySetInnerHTML={{
        __html: sanitizer(
          `${bio.slice(0, hideMore ? 200 : -1)}${
            !hideMore
              ? ''
              : `...<span>
            <span id="profile-read-more" class="read-more-button">
              Read More
            </span>
          </span>`
          }`.replaceAll('\r\n', '<br />'),
          {
            ADD_ATTR: ['target'],
          },
        ),
      }}
    ></span>
  );
};

const BioIdentity = ({ identity, href, showDivider }) => {
  if (!identity) return <></>;

  let content = identity;

  if (href) {
    content = <a href={href}>{identity}</a>;
  }

  return (
    <div style={{ display: 'inline-block' }}>
      {content}
      {showDivider && <span className="identity-separator">|</span>}
    </div>
  );
};

const ProfileHeaderUI = ({
  id,
  hero,
  avatar,
  entityType,
  isVerified,
  hasRecurringDeposit,
  isAuthenticated,
  isInfluencer,
  isSelfFollowing,
  onFollow,
  name,
  socialLinks,
  bio,
  profileData,
  showCheckoutModal,
  foundation,
  webUrl,
  hideFollowerCount,
  isSelf,
  showEditButton,
  setShowShareModal,
  uiStore,
  followerCount,
  followingCount,
  shareUrl,
  charity,
}) => {
  const { isMobile } = useMobile();

  const isUser = entityType === 'USER';
  const isCharity = entityType === 'NONPROFIT';
  const hasHero = Boolean(hero && hero?.full);
  const profileMetrics = [
    isInfluencer &&
      isAuthenticated && {
        title: 'total good',
        value: parseInt(profileData?.userGood?.alltimeGoodSum || 0) / 100,
      },
    {
      title: 'influenced',
      value: profileData?.userGood?.alltimeInfluencedCount,
    },
    {
      title: 'followers',
      value: profileData?.followerCount,
    },
    {
      title: 'following',
      value: profileData?.followingCount,
    },
  ].filter((b) => b);

  const onFollowClick = ({ isFollowers }) => {
    if (isAuthenticated) {
      if (isFollowers && followerCount === 0) return;
      if (!isFollowers && followingCount === 0) return;

      uiStore.openModal('FOLLOW_FOLLOWING', {
        title: isFollowers
          ? `${followerCount} Followers`
          : `${followingCount} Following`,
        pageContext: convertEntityTypeToId({ id, entityType }),
        showFollow: isAuthenticated,
        buttonLabel: 'Follow',
        isSelf: isSelf,
        isFollowers,
        entityName: name,
        followerCount: followerCount,
        followingCount: followingCount,
        isAuthenticated: isAuthenticated,
      });
    } else {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to see followers on a profile',
        action: () => {
          navigate('/login');
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
    }
  };

  useEffect(() => {
    function handleBack() {
      uiStore.closeModal();
    }

    window.addEventListener('popstate', handleBack);

    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, []);

  const profileIdentities = useMemo(() => {
    const { ein, city, state } = profileData || {};

    const propsList = [
      foundation && {
        identity: foundation,
      },
      Boolean(ein && isCharity) && {
        identity: `EIN: ${ein}`,
      },
      Boolean(city && state && isCharity) && {
        identity: `${city}, ${state}`,
      },
      Boolean(webUrl) && {
        identity: webUrl,
        href: webUrl,
      },
    ].filter((p) => p);

    const components = [];

    for (let i = 0; i < propsList.length; i++) {
      const props = propsList[i];
      components.push(
        <BioIdentity {...props} showDivider={i < propsList.length - 1} />,
      );
    }

    return components;
  }, [foundation, profileData, isCharity, webUrl]);

  return (
    <div className="profile-header">
      <div className="profile-header-hero">
        {hasHero && <div className="profile-header-hero-overlay" />}
        <div
          className={cx(
            `hero ${
              !hasHero && `background-gradient-${1 + ((name?.length || 0) % 6)}`
            }`,
            { 'empty-hero': !hasHero },
          )}
          style={
            hasHero
              ? {
                  backgroundImage: `url(${hero.full})`,
                }
              : undefined
          }
        />
      </div>
      <div className="profile-header-body">
        <div className="avatar-container">
          <ProfileAvatar
            avatar={avatar}
            entityType={entityType}
            isVerified={isVerified}
            hasRecurringDeposit={hasRecurringDeposit}
          />
        </div>
        <div className="action-container">
          {!isSelf && isCharity && (
            <ProfileAction
              variant="give"
              onClick={() => {
                showCheckoutModal?.();
              }}
            />
          )}
          {!isSelf && entityType === 'USER' && (
            <ProfileAction
              variant="gift"
              onClick={() => {
                showCheckoutModal?.();
              }}
            />
          )}
          <ProfileAction
            variant="share"
            onClick={() => {
              if (isMobile && navigator.share) {
                navigator.share({
                  title: `Share ${isSelf ? 'your' : 'this'} Cauze Profile`,
                  url: shareUrl,
                });
              } else {
                setShowShareModal(true);
              }
            }}
          />
          {!isSelf && (
            <ProfileAction
              variant={isSelfFollowing ? 'unfollow' : 'follow'}
              onClick={(e) => {
                if (isAuthenticated) {
                  onFollow(e);
                } else {
                  uiStore.openModal('CONFIRM', {
                    message: `Please Login or Signup to follow ${
                      name || 'this user'
                    }.`,
                    action: () => {
                      navigate('/login');
                    },
                    confirmText: 'Login/Signup',
                    cancelText: 'Not Now',
                    bodyMessage:
                      'Create your own Cauze account and join our community of givers.',
                  });
                }
              }}
            />
          )}
          {showEditButton && !isCharity && (
            <ProfileAction
              variant="edit"
              onClick={() =>
                (window.location.href = `/admin/${entityType.toLowerCase()}/${id}/profile`)
              }
            />
          )}
          {showEditButton && isCharity && (
            <ProfileAction
              variant="edit"
              onClick={() =>
                (window.location.href = `/admin/charity/${id}/profile`)
              }
            />
          )}
        </div>
        <div className="identity-container">
          <div className="identity-name">{name}</div>
          <div className="identity-foundation">{profileIdentities}</div>
        </div>
        <div className="socials-container">
          {Array.isArray(socialLinks) &&
            socialLinks.map((socialLink) => (
              <ProfileSocial
                key={socialLink.socialType}
                socialType={socialLink.socialType?.toLowerCase()}
                url={socialLink.url}
              />
            ))}
        </div>
        <div
          className="bio-container"
          style={{ marginTop: !isEmpty(socialLinks) ? '17px' : '0px' }}
        >
          {bio ? (
            <ProfileHeaderBio bio={bio} />
          ) : (
            <span className="empty-bio">{name} has not yet set their bio.</span>
          )}
        </div>

        {Boolean(charity) && (
          <div className="mb-3">
            <a
              href={`/charity/${charity.id}`}
              className="font-agenda-bold text-[#818699] inline-flex gap-x-1"
            >
              <span className="my-auto h-[24px]">
                <LinkIcon />
              </span>
              <span className="text-black leading-none h-[16px] my-auto pt-[1px]">
                {charity.name}
              </span>
            </a>
          </div>
        )}

        <div className="metrics-container">
          {Object.values(profileMetrics)
            .filter((metric) => {
              if (metric.title === 'followers' && hideFollowerCount) {
                return false;
              }

              if (!metric.value) return false;

              return true;
            })
            .map((metric) => (
              <ProfileMetric
                isCurrency={metric.title === 'total good'}
                title={metric.title}
                value={metric.value}
                onClick={
                  ['followers', 'following'].includes(metric.title)
                    ? () =>
                        onFollowClick({
                          isFollowers: metric.title === 'followers',
                        })
                    : undefined
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const ProfileHeader = ({
  showCheckoutModal,
  name,
  hero,
  id,
  entityType,
  avatar,
  isVerified,
  shareLink,
  hasRecurringDeposit,
  hideFollowerCount,
  isSelfFollowing = false,
  isInfluencer,
  onFollow,
  showEditButton = false,
  socialLinks,
  isAuthenticated,
  bio,
  foundation,
  webUrl,
  profileData,
  isSelf,
  uiStore,
  email,
  activeEntity,
  followerCount,
  followingCount,
  profileStore,
  charity,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const { isMobile } = useMobile();

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 115 });
    }
  }, []);

  return (
    <header className="profile-header flex-column">
      <ShareModal
        isOpen={showShareModal}
        onToggleClose={() => setShowShareModal(false)}
        shareUrl={shareLink}
        isSelf={isSelf}
      />
      <ProfileHeaderUI
        hero={hero}
        avatar={avatar}
        showCheckoutModal={showCheckoutModal}
        isVerified={isVerified}
        entityType={entityType}
        hasRecurringDeposit={hasRecurringDeposit}
        isAuthenticated={isAuthenticated}
        isInfluencer={isInfluencer}
        isSelfFollowing={isSelfFollowing}
        onFollow={onFollow}
        name={name}
        socialLinks={socialLinks}
        bio={bio}
        profileData={profileData}
        foundation={foundation}
        webUrl={webUrl}
        hideFollowerCount={hideFollowerCount}
        isSelf={isSelf}
        showEditButton={showEditButton}
        id={id}
        uiStore={uiStore}
        email={email}
        activeEntity={activeEntity}
        followerCount={followerCount}
        followingCount={followingCount}
        shareUrl={shareLink}
        profileStore={profileStore}
        setShowShareModal={setShowShareModal}
        charity={charity}
      />
    </header>
  );
};

export default withUserContext(
  inject('uiStore', 'authStore', 'profileStore')(observer(ProfileHeader)),
);
export { ProfileHeaderBio, BioIdentity };
